export default [{
    name: 'shipModel',
    type: 'gltfModel',
    path: 'models/star-sparrow/star-sparrow.gltf'
}, {
    name: 'planeDisplacementTexture',
    type: 'texture',
    path: 'textures/plane/map.jpg'
}, {
    name: 'planeAlphaTexture',
    type: 'texture',
    path: 'textures/plane/alphamap.jpg'
}, {
    name: 'particleModel',
    type: 'gltfModel',
    // path: 'models/test.glb',
    path: 'models/morphShapes.glb'
}, {
    name: 'jsModel',
    type: 'gltfModel',
    path: 'models/logos/js.glb'
}, {
    name: 'javaModel',
    type: 'gltfModel',
    path: 'models/logos/java.glb'
}, {
    name: 'pythonModel',
    type: 'gltfModel',
    path: 'models/logos/py.glb'
}
]